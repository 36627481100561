.dxc_icon{
    height: 100%;
    margin-right: 0.5em;
    max-height: 23px;
}

@media screen and (max-width: 400px) {
    .site-title-prefix {
        display: none;
    }
}

.navbar {
    border: none;
    border-radius: 0;
    font-size: 1em;
    min-height: 3.3em;
}
.navbar .menu.transition.visible{
    font-size: 15px;
}

.navbar-item:hover{
    background-color: #5c399e !important;
}
.ui.menu{
    border-radius: 0 !important;
}
.ui.menu.inverted .item::before{
    width: 0 !important;
    background-color: white;
}
.menu > .not.inverted > a{
    color: #424242 !important;
}
.menu > .not.inverted > a:hover,
.menu .item a:hover{
    border-bottom: none !important;
}
.navbar .menu .item:hover {
    background-color: rgba(255,255,255,0.08);
}
a:hover{
    cursor: pointer;
}
.not.inverted{
    color: initial !important;
}
.ui.menu .item.not.inverted:hover{
    background-color: #f2f2f2;
}
.navbar .menu .item .bars.icon{
    margin: 0;
}
