.rdt_TableHeader ~ div{
    border: 1px solid #dcdcdc;
    border-radius: 0.125rem;
}
.rdt_TableRow,.rdt_TableHead{
    font-size:13px !important;
}
/*.rdt_TableRow:nth-child(even):not(.rdt_ExpanderRow){
    background-color: #f9fafb;
}*/
.rdt_TableCol{
    align-items: baseline !important;
}
.financeTable .rdt_TableCol:hover,.financeTable .rdt_TableRow:hover{
    background-color: rgba(0,0,0,.05);
}
.rdt_TableCol_Sortable:hover{
    opacity: 1 !important;
}
.ui.table .placeholder{
    background-color: transparent;
}
.ui.table .placeholder .line{
    height: 0.125em;
    background: transparent;
}
.rdt_TableCell{
    padding: 0 0.35em !important;
}
.rdt_TableCell:first-of-type{
    padding-left: 0.75em !important;
}
.rdt_TableCell:last-of-type{
    padding-right: 0;
}
