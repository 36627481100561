/****TABLE OF CONTENTS****/
/*
  SEMANTIC UI
  FINANCE PORTAL CSS
  FINANCE UPLOAD CSS
  FINANCE VIEW CSS
  CA PORTAL CSS
  SHARED PORTAL CSS
  SHARED VIEW CSS
*/
/*************************/

/*---------------------SEMANTIC UI--------------------------*/
#root{
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#root > div{
  width: 100%;
}
.main-wrapper{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.page-content{
  flex: 1 1 auto;
}
body{
  font-size: 14px;
}
.font-size-standard{
  font-size: 1em !important;
}
.ui.basic.segment, .ui.basic.segments, .ui.segments .ui.basic.segment{
  border: none !important;
}
.ui.progress > .label{
  top: initial
}
.ui.grid .row{
  width: 100%;
}
.align-items-center{
  display: flex !important;
  align-items: center;
}
.justify-end{
  display: flex;
  justify-content: flex-end;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
#ca-filter .search{
  margin: 0;
  padding: 0;
}
.m-a{
  margin: auto !important;
}
.ui.container.fluid{
  padding: 0 2em;
}

/*---------------------FINANCE PORTAL CSS--------------------------*/
.page-header-title-finance {
  font-size: 24px;
  padding-bottom: 9px;
  margin: 40px 0 0;
}

/* Tabs styling */
#tabs ul > li  {
  width: 50%;
  border-top: 0;
}
#tabs {
  padding-bottom: 1rem;
}

/*---------------------FINANCE UPLOAD CSS--------------------------*/
.upload-page-body {
  max-width: 100rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  border-width: 1px;
  border-radius: .125rem;
  border-color: #dcdcdc;
  border-style: solid;
  background-color: #f9fafb;
  box-shadow: inset 0 2px 25px 0 rgba(34,36,38,.05);
  color: #3a3a3a;
  font-size: medium;
  margin-top: 1rem;
}

.dropzone-icon {
  font-size: 80px;
  padding: 1rem;
  color: #d4d3d3;
}

.filename {
  overflow-wrap:break-word;
}

.float-right {
  float: right;
  text-align: right;
}

.upload-page-action-buttons {
  margin: .3rem;
}

.upload-page-filename-buttons {
  margin-left: .5rem;
  padding: 0;
}

.file-list-header {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  width: 100%;
  align-items: flex-start;
}

.file-list-title {
  font-size: 20px;
}

.progress {
  height: 8px;
  margin: 0 0 1rem 0;
}

hr {
  margin: 0 0 1rem 0;
}

.fade-out {
  opacity: 0;
  transition: opacity 5000ms;
}

.upload-fail {
  color: red;
}

/*-----------------FINANCE VIEW CSS ----------------*/
/* -------------- Expand Row -----------------*/
/*finance expand row style table of downloads*/
.expandTable>tbody>tr>td,
.expandTable>tbody>tr>th {
  border-top: none;
  padding: 0;
}

/*may need to come back and take off these %s*/
.expandRowCA {
  float: right;
  width: 64%;
  font-size: 1.2rem;
  text-align: left;
}

.expandRowTime {
  text-indent: 6%;
  font-size: 1.2rem;
}

/* Row striping  */
.table tbody tr.mytable-hover-row:nth-of-type(odd) {
  background: #f2f2f2;
}
.table tbody tr:not(.mytable-hover-row) ~ tr.mytable-hover-row:nth-of-type(even) {
  background: #f2f2f2;
}
.table tbody tr:not(.mytable-hover-row) ~ tr.mytable-hover-row:nth-of-type(odd) {
  background: none;
}
.table tbody tr:nth-child(even) > td.reset-expansion-style {
  background: #f2f2f2;
}

/* Expand row styling*/
.table .table {
  background: inherit;
}

.table>tbody>tr>td, .table>tbody>tr>th {
  padding: .6rem;
}

/* IDK what this is. dont think it exists*/
.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: unset;
}
/*make all rows pointer cursor (indicate clickable)*/
.mytable-hover > tbody > tr.mytable-hover-row:hover {
  background-color: #ECECEC;
  cursor: pointer;
}

/* IDK what this is. dont think it exists*/
.react-bs-container-body tr {
  border-top-style: hidden !important;
}

.react-bs-container-header tr {
  border-bottom-style: hidden !important;
}

/* ---------------- CA PORTAL CSS ------------------ */
.page-header-title-ca {
  font-size: 24px;
  padding-bottom: 9px;
  margin: 40px 1rem 20px;
  border-bottom: 1px solid #eee;
}

/* ---------------- SHARED PORTAL CSS ------------------ */
.btn-danger.btn {
  background-color: #ca0000;
}

/* Not sure if this exists either*/
button.close {
  color: #707070;
  opacity: 1;
}

/*Used to make tooltips work on disabled buttons*/
.disabled-button-container {
  cursor: not-allowed;
  display: inline-block;
}
.enabled-button-container {
  cursor: pointer;
  display: inline-block;
}
.disabled-button-container > button {
  pointer-events: none;
}

label {
  font-size: small;
}

/* ---------------- Toasts  ------------------ */
/* toast classes */
.Toastify__toast {
  font-family: 'Avenir', sans-serif !important;
  margin: 1em 0 !important;
  padding: 1em 1.5em !important;
  line-height: 1.4285em !important;
  border-radius: .125rem !important;
}
.Toastify__toast--rtl {
}
.Toastify__toast-body {
}

/** handle the notification color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light,.Toastify__toast--default {
  background-color: #f0f0f0 !important;
  box-shadow: 0 0 0 1px #494949 inset,0 0 0 0 transparent !important;
  color: #424242 !important;
}
.Toastify__toast--info {
}
.Toastify__toast--success {
  box-shadow: 0 0 0 1px #9cc487 inset,0 0 0 0 transparent !important;
  background-color: #edfae5 !important;
  color: #2e6833 !important;
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
  box-shadow: 0 0 0 1px #ffaca6 inset,0 0 0 0 transparent !important;
  background-color: #ffe8e6 !important;
  color: #c00c00 !important;
}

.Toastify__progress-bar {
  color: #424242 !important;
  background-color: #502E91 !important;
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
  background: #502E91 !important;
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
  color: #2e6833 !important;
  background-color: #2e6833 !important;
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
  color: #c00c00 !important;
  background-color: #c00c00 !important;
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

.Toastify__progress-bar {
  background-color: #a94442;
}
.Toastify__close-button{
  color: #424242;
}

/*-----------------Autologout Progress Bar ------------*/
.progressBar{
  min-height: 3rem;
  text-align: center;
}
.progress-bar {
  font-size: 1.5rem;
  padding-top: .5rem;
}

/*-------------- Auto Logout Modal styles --------------*/
#refreshModal {
  top: 50%;
}
.modal-dialog > .modal-content {
  border: 0 none;
}

/* ---------------- SHARED VIEW CSS ------------------ */

/*Invoice Table styling*/
.react-bootstrap-table  {
  table-layout: fixed;
  padding: 0 1rem;
}

.col-headers {
  font-size: 1.5rem;
}

/*Archive and download col header formatters*/
.col-header-container {
  flex-direction: row;
  margin-bottom: .8rem;
}
.col-header-text {
  margin-left: 0.8rem;
}
.col-header-tooltip-container {
  float: right;
}
.col-header-tooltip-icon {
  color: cornflowerblue;
  font-size: 1.6rem;
  margin-right: 1rem;
  margin-top: -.5rem;
}

/*Used in an alert if table fails to load*/
.refresh-table-button {
  padding: 0 1rem;
}

/*-----------Filters & Panel ---------*/
.filter {
  display: flex;
  flex-direction: row;
}

.filter > div {
  margin: 1rem 0;
}

.panel {
  margin-top: 2rem;
  margin-bottom: 0;
  min-width: 35rem;
}

.invisible-label {
  visibility: hidden;
}

.panel-heading:hover{
  cursor: pointer;
  background-color: #ECECEC;
}

@media screen and (max-width: 1100px) {
  .filter {
    display: grid;
  }

  .filter > div {
    display: inline-block;
    margin: 1rem 0;
  }

  .invisible-label {
    display: none;
  }
}

/* ------------- Paginators --------------*/
.paginators {
  display:flex;
  flex-direction: row;
  margin: 0 1rem;
  padding-top: 1rem;
}

/* For when there is a page list paginator (when invoices > invoices per list)*/
.pageListExists {
  margin-left: 2rem;
  margin-right: 1rem;
}

.noPageList {
  margin-right: 1rem;
}

/*first paginator make sure not covered by ca list on finance*/
.paginators > div:first-child {
  z-index: 0;
}
/*move last paginator to right*/
.paginators > div:last-child {
  margin-left: auto;
}
/*paginator text*/
.pText{
  font-size: 1.1em;
  padding: 1rem 0;
}


/*------------------- Actions buttons styles --------------------*/
/* Used to display a fixed width span if a button is not visible */
.button-width {
  width: 39px; /* Must match actions-button-group button width*/
  max-width: 39px;
  display: inline-block;
}

/* Wrapper for action buttons so they appear in a row*/
.actions-button-group {
  display: flex;
}

/*.actions-button-group > * {
  margin-right: 0.25rem;
}*/

/* Small Filter panel*/
.filterTitle{
  margin-left: 1rem;
}

@media screen and (max-width: 766px) {
  .table > tbody > tr > td {
    white-space: normal !important;
  }
}

/* Tooltips for view, download, archive, delete */
.tooltip > .tooltip-inner {
  padding: .3rem;
  margin-left: -7rem;
  min-width: 8rem;
  text-align: center;
  border-radius: 0;
}

/*Disabled Download tooltip*/
#disabled-download-tooltip > .tooltip-inner {
  margin-left: -17rem;
  min-width: 18rem;
}
/*Archive button tooltip */
#archive-tooltip > .tooltip-inner {
  margin-left: -22rem;
  min-width: 23rem;
}

/*Tooltip for archive column table header */
#archive-header-tooltip > .tooltip-inner {
  margin-left: -17rem;
  width: 18rem;
}

/*Tooltip for delete column table header */
#download-header-tooltip > .tooltip-inner {
  margin-left: -18rem;
  width: 19rem;
}

