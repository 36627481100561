.ca-filter {
    font-size: 1.4rem;
    min-width: 32rem;
}

.placeholder-text {
    color: #3c3c3c
}

.status-filter {
    font-size: 1.4rem;
    min-width: 21rem;
}

.date-filter {
    display:flex;
    flex-direction: row;
}

.date-comp-filter {
    font-size: 1.4rem;
    margin-right: .5rem;
    width: 15rem;
}

.react-datepicker__input-container input {
    font-size: 1.4rem;
    text-indent: 1rem;
    line-height: 3.4rem;
    min-width: 10rem;
    max-width: 15rem;
    font-weight: normal;
    border-width: 1px;
    color: hsl(0,0%,20%);
    border-radius: 4px;
    border-color: hsl(0,0%,80%);
    border-style: solid;
}

.react-datepicker__month {
    margin: 0.6rem 1rem;
}

.react-datepicker__day-name, .react-datepicker__day {
    width: 2.2rem;
    line-height: 1.8rem;
    margin: 0.166rem;
    font-size: 1.4rem;
}

.react-datepicker__current-month {
    font-size: 1.4rem;
}

.react-datepicker__navigation {
    top: 1rem;
    line-height: 1.7rem;
    border: 0.45rem solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1rem;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1rem;
}
  
.resetFilter {
    margin-left: 1rem;
    display: inline-block
}

@media screen and (max-width: 1100px) {
    .resetFilter {
      float: right
    }
}