.error-wrapper {
   margin: 10rem;
}

.error-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
  
.error-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }

.error-text {
    margin-bottom: 5rem;
}