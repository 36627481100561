body {
    position: relative;
    min-height: 100vh;
}

footer{
    width: 101%;
}

#footer{
    height: 100%;
    width: 100%;
    font-size: 16px;
}
#footer .menu{
    height: 100%;
    font-size: 1em;
}
